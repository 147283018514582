import type { LinkProps } from "@remix-run/react";
import type { ButtonHTMLAttributes } from "react";
import { forwardRef } from "react";

import { Link } from "~/components/Link";
import { cn } from "~/utils/classnames";

type BaseProps = {
  variant?: "fill" | "outline" | "ghost" | "link";
  color?: "primary" | "black" | "white" | "danger" | "success" | "warning";
  size?: "xs" | "sm" | "md" | "lg";
  uppercase?: boolean;
  disabled?: boolean;
};

type ButtonProps = BaseProps &
  (
    | (ButtonHTMLAttributes<HTMLButtonElement> & {
        as?: "button";
      })
    | (LinkProps & {
        as: "link";
      })
  );

export const Button = forwardRef<any, ButtonProps>(function Button(
  {
    variant = "fill",
    color = "primary",
    size = "md",
    uppercase = false,
    className,
    children,
    ...props
  }: ButtonProps,
  ref
) {
  const customClassName = cn(
    "rounded-full font-medium text-sm flex items-center justify-center disabled:opacity-50 disabled:pointer-events-none active:scale-[0.98] text-center",
    variant === "fill" && [
      color === "primary" && "bg-primary hover:bg-primary-600",
      color === "black" && "bg-grey-950 hover:bg-grey-900",
      color === "danger" && "bg-danger-500 hover:bg-danger-600",
      color === "success" && "bg-success-500 hover:bg-success-600",
      color === "warning" && "bg-warning-500 hover:bg-warning-600",
      color === "white" ? "bg-white text-grey-950 hover:bg-grey-50" : "text-white",
    ],
    variant === "outline" && [
      "border",
      color === "primary" && "border-primary text-primary hover:bg-primary-50",
      color === "black" && "border-grey-950 text-grey-950 hover:bg-grey-50",
      color === "danger" && "border-danger text-danger hover:bg-danger-50",
      color === "success" && "border-success text-success hover:bg-success-50",
      color === "warning" && "border-warning text-warning hover:bg-warning-50",
      color === "white" && "border-white text-white hover:bg-grey-50",
    ],
    variant === "ghost" && [
      color === "primary" && "text-primary hover:bg-primary-50",
      color === "black" && "text-grey-950 hover:bg-grey-50",
      color === "danger" && "text-danger hover:bg-danger-50",
      color === "success" && "text-success hover:bg-success-50",
      color === "warning" && "text-warning hover:bg-warning-50",
      color === "white" && "text-white hover:bg-grey-50",
    ],
    variant === "link" && [
      "underline",
      color === "primary" && "text-primary hover:text-primary-600",
      color === "black" && "text-grey-950 hover:text-grey-900",
      color === "danger" && "text-danger hover:text-danger-600",
      color === "success" && "text-success hover:text-success-600",
      color === "warning" && "text-warning hover:text-warning-600",
      color === "white" && "text-white hover:text-grey-900",
    ],
    size === "xs" && ["h-7 px-3", "gap-2"],
    size === "sm" && ["h-10 px-4", "gap-2"],
    size === "md" && ["h-12 px-5", "gap-3"],
    size === "lg" && ["h-14 px-6", "gap-3"],
    uppercase && "uppercase",
    props.disabled && "opacity-50 pointer-events-none",
    className
  );

  if (props.as === "link") {
    return (
      <Link className={customClassName} ref={ref} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <button className={customClassName} ref={ref} {...props}>
      {children}
    </button>
  );
});
